h1.section_text {
    display: flex;
    background-color: #E10003;
    color: #000000;
    font-family:  Verdana, Arial, Helvetica, sans-serif;
    font-size: 25px;
    line-height: 15px;
    width: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    border: medium;
    border-color: #000000;
    border-radius: 4px;
    margin-top: 3.5px;
    margin-right: 3.5px;
}